import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import '../styles/404.scss'

export default function ErrorPage() {

    useEffect(() => {
        onmousemove = (ev) => {
            const cursor = document.querySelector('#id_cursor')
            const cursorPoint = document.querySelector('#id_cursor_point')

            cursor.style.top = `${ev.pageY}px`
            cursor.style.left = `${ev.pageX}px`
            cursorPoint.style.top = `${ev.pageY}px`
            cursorPoint.style.left = `${ev.pageX}px`
        }
    }, [])

    return (
        <Layout>
            <span id="id_cursor" className="cursor" />
            <span id="id_cursor_point" />

            <div className="page_error">
                <div className="block center">
                    <div className="relative">
                        <h2>Cette page n'existe pas</h2>
                        <Link to="/">Retour à l'accueil</Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}